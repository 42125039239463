import styled from 'styled-components'
import { Button } from 'antd'

export const ContainerModalMeetingComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px;
`

export const StyledButtonAI = styled(Button)`
    background-color: transparent;
    position: relative;
    transition: all .35s ease;
    color: #fff;
    z-index: 1;
    border-color: transparent;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90.81deg, #614ac9 38.15%, #00cfab 134.9%);
      border-radius: 1rem;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(91.22deg, #614ac9 -31.81%, #00cfab 111.75%);
      border-radius: 1rem;
      z-index: -1;
      opacity: 0;
      transition: opacity .8s ease;
    }

    &:hover {
      color: #fff !important;
      background: transparent !important;
      border-color: transparent !important;

      &:after {
        opacity: 1;
      }
    }
`