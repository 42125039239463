import { Button } from 'antd'
import styled from 'styled-components'

export const StyledComponentContainer = styled.div``
export const StyledComponentContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  margin: 10px;
`
export const StyledComponentPromptItem = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
`

export const StyledComponentPromptItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const StyledComponentPromptItemNumber = styled.div`
  background-color: #e2e7ff;
  padding: 5px;
  color: #5333c1;
  font-weight: bold;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledComponentPromptItemText = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
  margin-right: 1rem;
`
export const StyledComponentPromptItemDeleteImg = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  text-align: center;
`
export const StyledTestComponentButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  margin: 0 10px 1.25rem;
`

export const StyledButtonAI = styled(Button)`
    background-color: transparent;
    position: relative;
    transition: all .35s ease;
    color: #fff;
    z-index: 1;
    border-color: transparent;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90.81deg, #614ac9 38.15%, #00cfab 134.9%);
      border-radius: 1rem;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(91.22deg, #614ac9 -31.81%, #00cfab 111.75%);
      border-radius: 1rem;
      z-index: -1;
      opacity: 0;
      transition: opacity .8s ease;
    }

    &:hover {
      color: #fff !important;
      background: transparent !important;
      border-color: transparent !important;

      &:after {
        opacity: 1;
      }
    }
`