import { Button, Col, Form, FormInstance, Input, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DeleteOutlined, EditOutlined, LoadingOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons'
import { useMeeting } from '../../../../context/meetingContext'
import InputSelectEmails from '../inputEmails'
import { toast } from 'react-toastify'

type PropsRecipients = {
  onClickDelete: (value: string, isTemporal?: boolean) => void
  form: FormInstance
}

const Recipients: React.FC<PropsRecipients> = ({
  onClickDelete,
  form
}) => {
  const { isLoadingUpdateMeeting, meetingItem } = useMeeting()
  const [recipients, setRecipients] = useState<Meetings.Recipients[]>([])
  const [temporalRecipients, setTemporalRecipients] = useState<Meetings.Recipients[]>([])
  const [valueEdit, setValueEdit] = useState<string>('')

  useEffect(() => {
    const formRecipients = form.getFieldValue('recipients') || []
    setRecipients(formRecipients)
  }, [form])

  const inputEmailsRef = useRef<{ clearEmails: () => void } | null>(null)
  
  const handleAddRecipient = () => {
    const inputEmails = form.getFieldValue('inputEmails') || []
    
    if (inputEmails.length === 0) {
      toast.error('Please enter an email', {
        position: 'top-right',
        toastId: 'email-already-exists'
      })
      return
    }

    // Si el recipient ya existe en recipients o temporalRecipients, no lo agregamos
    const isEmailAlreadyExists = recipients.some(recipient => recipient.email === inputEmails[0].email) || temporalRecipients.some(recipient => recipient.email === inputEmails[0].email)
    if (isEmailAlreadyExists) {
      toast.error('Some emails already exists', {
        position: 'top-right',
        toastId: 'email-already-exists'
      })
      return
    }
    
    // Actualizar la lista de recipients visible
    const newRecipients = [...recipients, ...inputEmails]
    setRecipients(newRecipients)
    
    // Mantener la lista de recipients temporales para actualizar
    const newTemporalRecipients = [...temporalRecipients, ...inputEmails]
    setTemporalRecipients(newTemporalRecipients)
    form.setFieldValue('temporalRecipients', newTemporalRecipients)
    
    // Limpiar el input
    form.setFieldValue('inputEmails', [])
    inputEmailsRef.current?.clearEmails()
  }

  const handleEmailsChange = (newEmails: Meetings.Recipients[]) => {
    form.setFieldValue('inputEmails', newEmails)
  };

  const handleDeleteRecipient = (email: string) => {
    // Eliminar de la lista visible
    const newRecipients = recipients.filter(recipient => recipient.email !== email)
    setRecipients(newRecipients)

    // Verificar si el recipient a eliminar es temporal o original
    const isTemporalRecipient = temporalRecipients.some(r => r.email === email)

    if (isTemporalRecipient) {
      // Si es temporal, solo lo eliminamos de la lista temporal
      const newTemporalRecipients = temporalRecipients.filter(recipient => recipient.email !== email)
      setTemporalRecipients(newTemporalRecipients)
      form.setFieldValue('temporalRecipients', newTemporalRecipients)
    } else {
      // Si es un recipient original, lo agregamos a la lista de eliminados
      const recipientToDelete = meetingItem?.recipients.find(r => r.email === email)
      if (recipientToDelete) {
        const currentDeletedRecipients = form.getFieldValue('temporalDeleteRecipients') || []
        form.setFieldValue('temporalDeleteRecipients', [...currentDeletedRecipients, recipientToDelete])
      }
    }

    onClickDelete(email, isTemporalRecipient)
  }
  
  const handleEditRecipient = (email: string) => {
    const newRecipients = recipients.map(recipient => 
      recipient.email === email ? { ...recipient, isEditing: true } : recipient
    )
    setRecipients(newRecipients)
  }
  
  const editRecipient = (email: string, newEmail: string) => {
    // Si no hay nuevo email o ya existe, solo cancelamos la edición
    if (!newEmail || (newEmail !== email && recipients.some(recipient => recipient.email === newEmail))) {
      const newRecipients = recipients.map(recipient => 
        recipient.email === email ? { ...recipient, isEditing: false } : recipient
      )
      setRecipients(newRecipients)
      toast.error('Email already exists', {
        position: 'top-right',
        toastId: 'email-already-exists'
      })
      return
    }

    if (newEmail !== email) {
      // Actualizar la lista visible de recipients
      const newRecipients = recipients.map(recipient => 
        recipient.email === email 
          ? { ...recipient, email: newEmail, isEditing: false } 
          : recipient
      )
      setRecipients(newRecipients)

      // Verificar si el email editado era un recipient temporal
      const wasTemporalRecipient = temporalRecipients.some(r => r.email === email)

      if (wasTemporalRecipient) {
        // Actualizar la lista de recipients temporales
        const newTemporalRecipients = temporalRecipients.map(recipient =>
          recipient.email === email
            ? { ...recipient, email: newEmail }
            : recipient
        )
        setTemporalRecipients(newTemporalRecipients)
        form.setFieldValue('temporalRecipients', newTemporalRecipients)
      } else {
        // Si era un recipient original, agregarlo a la lista de eliminados y nuevos
        const recipientToDelete = recipients.find(r => r.email === email)
        if (recipientToDelete) {
          const currentDeletedRecipients = form.getFieldValue('temporalDeleteRecipients') || []
          form.setFieldValue('temporalDeleteRecipients', [...currentDeletedRecipients, recipientToDelete])

          // Agregar el nuevo email a la lista temporal
          const newTemporalRecipients = [...temporalRecipients, { 
            email: newEmail, 
            isEditing: false, 
            first_name: '', 
            last_name: '' 
          }]
          setTemporalRecipients(newTemporalRecipients)
          form.setFieldValue('temporalRecipients', newTemporalRecipients)
        }
      }
    } else {
      // Si el email no cambió, solo quitamos el modo de edición
      const newRecipients = recipients.map(recipient => 
        recipient.email === email ? { ...recipient, isEditing: false } : recipient
      )
      setRecipients(newRecipients)
    }
  }
  
  return (
    <>
      <Row gutter={16}>
        <Col span={22}>
          <Form.Item 
            name="inputEmails"
          >
           <InputSelectEmails 
              ref={inputEmailsRef}
              onChange={handleEmailsChange}
              onKeyDown={handleAddRecipient}
           />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button 
            type="primary" 
            onClick={handleAddRecipient}
          >
            {isLoadingUpdateMeeting ? <LoadingOutlined /> : '+'}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {recipients?.map((recipient, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
                height: '40px'
              }}
            >
              {recipient.isEditing ? (
                <Input
                  defaultValue={recipient.email}
                  onBlur={(e) => {
                    const newEmail = e.target.value;
                    editRecipient(recipient.email, newEmail);
                  }}
                  onChange={(e) => {
                    const newEmail = e.target.value;
                    setValueEdit(newEmail)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      editRecipient(recipient.email, valueEdit)
                    }
                  }}
                  autoFocus
                />
              ) : (
                <span
                  onClick={() => handleEditRecipient(recipient.email)}
                  style={{ 
                    cursor: 'pointer', 
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {recipient.email}
                </span>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EditOutlined
                  style={{ margin: '5px', cursor: 'pointer' }}
                  onClick={() => handleEditRecipient(recipient.email)}
                />
                <DeleteOutlined
                  onClick={() => handleDeleteRecipient(recipient.email)}
                  style={{ margin: '5px', cursor: 'pointer' }}
                />
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </>
  )
}

export default Recipients
