import React from 'react'
import { Skeleton, Space } from 'antd'

const SkeletonForm: React.FC = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          active
          paragraph={{
            rows: 1,
            width: ['100%']
          }}
        />
      ))}
    </Space>
  )
}

export default SkeletonForm
