import { Navigate, useLocation } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import { useContext } from 'react'

export const UseAuth = () => {
  const isAuthenticated = !!localStorage.getItem('access-token')
  const isValidated = !!localStorage.getItem('is_validated')

  return { isAuthenticated, isValidated }
}

export const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated, isValidated } = UseAuth()
  const location = useLocation()

  if (!isAuthenticated || !isValidated) {
    localStorage.removeItem('access-token')
    localStorage.removeItem('is_active')
    return <Navigate to="/login" replace state={{ path: location.pathname }} />
  }

  return children
}

export const PublicRoute = ({ children }: any) => {
  const { isAuthenticated } = UseAuth()
  const { isAnonymous } = useContext(JamyContext)
  const location = useLocation()
  const redirect = location.search
    ? location.search.split('redirect=')[1]
    : isAnonymous ? '/register' : '/welcome'
  return isAuthenticated === true ? (
    <Navigate to={redirect} replace state={{ path: location.pathname }} />
  ) : (
    children
  )
}
