import React, { useContext, useEffect, useState } from 'react'
import { StyledH2V2 } from '../../../styles/styledComponents'
import edit from '../../../assets/icons/edit.svg'
import deleteImg from '../../../assets/icons/delete.svg'
import { Button, Form, Input, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import MeetingsDataService from '../../../services/meetings'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import JamyContext from '../../../context/jamyContext'
import SkeletonGeneration from './skeletonMeetingComponent'

type PropsGeneration = {
  generationItem?: Meetings.Reports
  getMeetingDetail: (meetingId: string) => void
  status: string
}

const Generation: React.FC<PropsGeneration> = ({
  generationItem,
  getMeetingDetail,
  status
}) => {
  const [seeMoreText, setSeeMoreText] = useState('See more')
  const [valueEdit, setValueEdit] = useState(false)
  const [valueArray, setValueArray] = useState<string[]>([])
  const [form] = useForm()
  const { meeting_id } = useParams()
  const { isAnonymous } = useContext(JamyContext)
  const [removeGenerationModal, setRemoveGenerationModal] = useState(false)
  const [loadingRemoveGeneration, setLoadingRemoveGeneration] = useState(false)
  const [componentIdToRemove, setComponentIdToRemove] = useState<string | null>(null)

  useEffect(() => {
    if (generationItem) {
      if (generationItem.result && generationItem.result.result) {
        setValueArray(
          generationItem.result.result[0].value.split('\n\n').slice(0, 2)
        )
        form.setFieldValue('value', generationItem.result.result[0].value)
      }
    }
  }, [generationItem])

  // Agregar polling cuando el status es 'processing'
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    
    if (status === 'processing' && meeting_id) {
      intervalId = setInterval(() => {
        getMeetingDetail(meeting_id);
      }, 10000); // Verificar cada 10 segundos
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, meeting_id]);

  const seeMore = (more: boolean) => {
    if (more) {
      setSeeMoreText('Hide')
      setValueArray(
        generationItem
          ? generationItem.result.result[0].value.split('\n\n')
          : []
      )
    } else {
      setSeeMoreText('See more')
      setValueArray(
        generationItem
          ? generationItem.result.result[0].value.split('\n\n').slice(0, 2)
          : []
      )
    }
  }

  const updateMeeting = (data: any, arg: string) => {
    const meetingService = new MeetingsDataService()
    if (generationItem) {
      meetingService
        .updateMeetingComponent(generationItem.id, data)
        .then(() => {
          setValueEdit(!valueEdit)
          getMeetingDetail(meeting_id ? meeting_id : '')
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
        })
    }
  }

  const onClicFinishValue = (data: { value: string }) => {
    if (generationItem) {
      const result = {
        result: {
          result: [{ value: data.value }]
        }
      }
      updateMeeting(result, generationItem.component.name)
    }
  }
  
  const handleRemoveGeneration = () => {
    setComponentIdToRemove(generationItem?.id || null)
    setRemoveGenerationModal(true)
  }
  
  const onRemoveFromFolder = async (componentId: string | null) => {
    const meetingService = new MeetingsDataService()
    setLoadingRemoveGeneration(true)
    const report_ids = []
    if (componentId && meeting_id) {
      report_ids.push(componentId)
      try {
        await meetingService.updateMeeting({ report_ids }, meeting_id)
        toast.success(`Meeting component removed from meeting`, { theme: 'colored', toastId: 'remove-from-folder' })
        setRemoveGenerationModal(false)
        getMeetingDetail(meeting_id)
      } catch (error) {
        toast.error(`Meeting component not removed from meeting`, { theme: 'colored', toastId: 'remove-from-folder' })
      } finally {
        setLoadingRemoveGeneration(false)
      }
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline'}}>
        <StyledH2V2>
          {generationItem && generationItem.component.name}
        </StyledH2V2>
        {
          status === 'done' && (
            !isAnonymous && (
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <img
                    src={edit}
                    alt="edit"
                    style={{ cursor: 'pointer', margin: '0 10px' }}
                    onClick={() => {
                      setValueEdit(!valueEdit)
                    }}
                  />
                <img
                  src={deleteImg}
                  alt="delete"
                  style={{ cursor: 'pointer', margin: '0 10px' }}
                  onClick={handleRemoveGeneration}
                />
              </div>
            )
          )
        }
      </div>
      {
        status === 'done' && (
          <div hidden={valueEdit}>
        {valueArray.map((p, index) => {
          return (
            <p key={index} style={{ lineHeight: '24px' }}>
              {p}
            </p>
          )
        })}
        <p
          style={{ color: '#E0AA25', cursor: 'pointer' }}
          onClick={() => seeMore(seeMoreText === 'See more' ? true : false)}
        >
          <strong>{seeMoreText}</strong>
            </p>
          </div>
        )
      }
      {
        status === 'processing' ? (
          <SkeletonGeneration />
        ) : (
          <Form form={form} onFinish={onClicFinishValue} hidden={!valueEdit}>
            <Form.Item name={'value'}>
              <Input.TextArea
                size="middle"
                style={{ height: '300px' }}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        )
      }
      <Modal
        open={removeGenerationModal}
        onCancel={() => setRemoveGenerationModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setRemoveGenerationModal(false)}
            disabled={loadingRemoveGeneration}
          >
            Cancel
          </Button>,
          <Button
            key="remove"
            type="primary"
            onClick={() => onRemoveFromFolder(componentIdToRemove)}
            disabled={loadingRemoveGeneration}
          >
            {loadingRemoveGeneration ? 'Removing...' : 'Remove'}
          </Button>
        ]}
        title="Remove meeting component from meeting"
        width={600}
      >
        <p>Are you sure you want to remove this meeting component from the meeting?</p>
      </Modal>
    </div>
  )
}

export default Generation
