import { ReactNode } from 'react'
import { Step } from 'react-joyride'

const StepTemplateContent: ReactNode = (
  <div style={{ fontWeight: '400', fontSize: '15px', textAlign: 'left' }}>
    <p>
      <strong>Form Component</strong>
    </p>
    <p>
      Form Component consists of items that can be variables or questions that
      Jamy will fill out based on the Meeting content. Each item has a name,
      type and description. This type of Component is specially useful if you
      want to, for example, gather customer attributes, answer specific
      questions, fill out dates, choose from specific multiple options, etc.
    </p>
    <p>
      <strong>Generation Component</strong>
    </p>
    <p>
      A Generation Component consists of a set of instructions that Jamy will
      follow to generate a specific text. You can add as much instructions as
      you want. For example, the “Summary” Component is a Generation Component.
    </p>
  </div>
)

const AIButtonContent = (
  <div style={{ fontWeight: '400', fontSize: '15px', textAlign: 'left' }}>
    <p>
      Write a description for your Component and Jamy will generate it
      automatically with AI.
    </p>
  </div>
)

export const steps: Step[] = [
  {
    content: StepTemplateContent,
    locale: {
      skip: (
        <strong aria-label="skip" style={{ color: '#E0AA25' }}>
          Skip
        </strong>
      ),
      next: 'Next'
    },
    placement: 'right',
    target: '.walk-target',
    styles: { options: { width: 610 } },
    disableBeacon: true
  },
  {
    content: AIButtonContent,
    locale: { last: 'GO' },
    placement: 'bottom',
    target: '.AI-button',
    styles: { options: { width: 610 } },
    disableBeacon: true
  }
]
