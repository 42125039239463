import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import MeetingsDataService from '../../services/meetings'
import { StyledH1V2 } from '../../styles/styledComponents'
import Loading from '../../components/loading'
import Pill from '../../components/shared/pills'
import participants from '../../assets/pills/participants.svg'
import calendar from '../../assets/pills/calendar.svg'
import custom_words from '../../assets/pills/custom_words.svg'
import clock from '../../assets/pills/clock.svg'
import send from '../../assets/pills/send.svg'
import chat from '../../assets/icons/chat.svg'
import moment from 'moment'
import { Drawer } from 'antd'
import CustomWordsContainer from '../customWordsContainer'
import { useForm } from 'antd/es/form/Form'
import TasksContainer from '../tasksContainer'
import TopicsContainer from '../topicsContainer'
import ChatContainer from '../chatContainer'
import TranscriptContainer from '../transcriptContainer'
import { RecordingButton } from './styledComponents'
import { PlayCircleOutlined } from '@ant-design/icons'
import UnlockFeature from '../../components/unlockFeature'
import { StyledPillContent } from '../../components/shared/pills/styledComponents'
import Generation from '../../components/templates/generation'
import Forms from '../../components/templates/forms'
import SendNotes from './components/sendNotes'
import { useMeeting } from '../../context/meetingContext'
import { useMeetingActions } from '../../hooks/useMeetingActions'
import MessageBox from '../../components/messageBox'
import AddToFolder from '../../components/forms/folders/addToFolder'
import ModalMeetingComponent from '../../components/modalMeetingComponent'

const MeetingDetialsContainer: React.FC = () => {
  const [sendNotesRecipientsForm] = useForm()
  const [showModal, setShowModal] = useState(false)
  const [showModalChat, setShowModalChat] = useState(false)
  const [showModalCW, setShowModalCW] = useState(false)
  const { permissions, isAuthenticated, getUser } = useContext(JamyContext)
  const [updateArg, setUpdateArg] = useState('')
  const [closePopOver, setClosePopOver] = useState(false)
  const { meetingItem, isLoadingMeeting } = useMeeting()
  const { updateMeeting, getMeetingDetail } = useMeetingActions()
  const { meeting_id } = useParams()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token') || ''
  const emailParam = searchParams.get('email') || ''
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const meetingDataService = new MeetingsDataService()
  const [open, setOpen] = useState(false)
  const [messageBox, setMessageBox] = useState<ReactNode>()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (isAuthenticated && meeting_id) {
      navigate(pathname)
      getMeetingDetail(meeting_id?.split('&')[0])
    } else {
      // Verificar acceso
      if (meeting_id) {
        const accessToken = localStorage.getItem('access-token')
        const isValidated = localStorage.getItem('is_validated')
        if (accessToken && isValidated) {
          // Si hay token en localStorage, obtener detalles directamente
          getMeetingDetail(meeting_id)
        } else if (tokenParam) {
          // Verificar banner de email si existe
          if (emailParam) {
            meetingDataService
              .getMeetingBanner(emailParam)
              .then((response) => {
                setMessageBox(validateMessageBox(response.data))
              })
              .catch((e) => console.error(e))
          }
          // Si hay token en params, obtener versión anónima
          getAnonymousVersion()
        } else {
          // Sin token, redirigir a login
          navigate('/login')
          localStorage.removeItem('access-token')
          localStorage.removeItem('is_active')
        }
      }
    }
  }, [meeting_id, isAuthenticated])

  /**@description Funcion para obtener los permisos, obtener los detalles de la reunion con usuario anonimo
  y crear isAnonymous en el estado*/
  const getAnonymousVersion = async () => {
    if (tokenParam) {
      localStorage.setItem('access-token', tokenParam)
    }
    if (meeting_id) {
      try {
        await getMeetingDetail(meeting_id)
      } catch (error: any) {
        if (error.response.status === 403) {
          navigate('/login')
          localStorage.removeItem('access-token')
          localStorage.removeItem('is_active')
        }
      }
    }
    getUser()
  }

  useEffect(() => {
    if (meetingItem) {
      // TODO: Add summary
      // summaryForm.setFieldValue('summary', meetingItem?.summary)
    }
  }, [meetingItem])

  useEffect(() => {
    if (updateArg !== '' && meetingItem) {
      updateMeeting(meetingItem, updateArg)
    }
  }, [updateArg])

  const validateMessageBox = (response: Meetings.BannerResponse) => {
    let messageComponent: ReactNode
    let orgUsers = ''
    for (let index = 0; index < response?.org_users?.length; index++) {
      orgUsers =
        orgUsers +
        `${index == 0
          ? ' '
          : index > 0 && index < response?.org_users?.length - 1
            ? ', '
            : ' and '
        }` +
        response.org_users[index]
    }
    switch (response.type) {
      case 'sign_up':
        messageComponent = (
          <p style={{ margin: '0' }}>
            <strong>Create a Jamy account for free to see the full report</strong>
          </p>
        )
        break
      case 'log_in':
        messageComponent = (
          <p style={{ margin: '0' }}>
            <strong>Login</strong> with your account ( {response.email} ) to see the
            full report and <strong>collaborate with {orgUsers}</strong> in the{' '}
            <strong>{response.organization_name}</strong> team in Jamy.
          </p>
        )
        break
      case 'join_org':
        messageComponent = (
          <p style={{ margin: '0' }}>
            Join <strong> {orgUsers} </strong> in the{' '}
            <strong> {response.organization_name} </strong>
            team in Jamy. <strong>Joining is free!</strong>
          </p>
        )
        break
      default:
        break
    }
    return messageComponent
  }

  // RENDERS
  const renderParticipants = (participants: string[] | undefined) => {
    return participants ? (
      <div style={{ padding: '10px' }}>
        {participants.map((participant, index) => {
          return (
            <p style={{ margin: '0' }} key={index}>
              {participant}
            </p>
          )
        })}
      </div>
    ) : (
      <div>NONE</div>
    )
  }

  const renderRecording = (
    urlRecording: string | undefined,
    urlFile: string | undefined
  ) => {
    return urlRecording ? (
      <div style={{ width: '100%' }}>
        <TranscriptContainer
          open={open}
          urlFile={urlFile}
          urlVideo={urlRecording}
          transcriptJSON={meetingItem ? meetingItem.transcript_segments : []}
        />
      </div>
    ) : (
      <div>no disponible</div>
    )
  }

  const onClicChat = (idBot: string, meeting_bot_id: string) => {
    navigate(`/meetChat/${meeting_bot_id}/${idBot}`)
  }

  return isLoadingMeeting ? (
    <Loading />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MessageBox messageBox={messageBox} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledH1V2 style={{ marginRight: '20px' }}>
          {meetingItem?.name}
        </StyledH1V2>
        <Pill
          getMeetingDetail={getMeetingDetail}
          type={undefined}
          name={`Share Report`}
          icon={send}
          onClick={() => {}}
          closePopOver={closePopOver}
          content={
            <SendNotes
              setClosePopOver={setClosePopOver}
              closePopOver={closePopOver}
              form={sendNotesRecipientsForm}
            />
          }
        />
        {/* Add to folder component */}
        <AddToFolder />
      </div>
      <div style={{ display: 'flex', margin: '15px 0' }}>
        <Pill
          type="primary"
          name={`${meetingItem?.participants.length.toString()} Participants`}
          icon={participants}
          onClick={() => {}}
          content={renderParticipants(meetingItem?.participants)}
          getMeetingDetail={getMeetingDetail}
        />
        <Pill
          type="primary"
          name={moment(meetingItem?.start_time).format('MMM Do, YYYY hh:mm a')}
          icon={calendar}
          getMeetingDetail={getMeetingDetail}
        />
        <Pill
          type="primary"
          name={`${meetingItem?.duration} min`}
          icon={clock}
          getMeetingDetail={getMeetingDetail}
        />
        {permissions && permissions.includes('custom_words') ? (
          <Pill
            getMeetingDetail={getMeetingDetail}
            type="secondary"
            name={`Custom Words`}
            icon={custom_words}
            content={<CustomWordsContainer />}
            onClick={() => {}}
          />
        ) : (
          <StyledPillContent
            type="secondary"
            onClick={() => setShowModalCW(!showModalCW)}
          >
            <img
              src={custom_words}
              alt={'Custom Words'}
              width={'14px'}
              height={'14px'}
            />
            <p>Custom Words</p>
          </StyledPillContent>
        )}

        {permissions && permissions.includes('chat') ? (
          <Pill
            getMeetingDetail={getMeetingDetail}
            type="secondary"
            name={`Chat`}
            icon={chat}
            content={<ChatContainer />}
            onClick={() => {
              if (meetingItem) {
                onClicChat(meetingItem?.id, meetingItem?.meeting_bot_id)
              }
            }}
          />
        ) : (
          <StyledPillContent
            type="secondary"
            onClick={() => setShowModalChat(!showModalChat)}
          >
            <img src={chat} alt={'Chat'} width={'14px'} height={'14px'} />
            <p>Chat</p>
          </StyledPillContent>
        )}

        <RecordingButton
          onClick={() => {
            setOpen(true)
            setShowModal(true)
          }}
        >
          <PlayCircleOutlined />
          <p style={{ margin: '0 5px' }}>Recording & Transcript</p>
        </RecordingButton>

        {permissions && permissions.includes('video') ? (
          <Drawer
            title="Transcript"
            onClose={onClose}
            open={open}
            width="100%"
            placement="right"
          >
            {renderRecording(
              meetingItem?.meeting_file,
              meetingItem?.transcript
            )}
          </Drawer>
        ) : (
          <UnlockFeature
            namePlan="Pro"
            featureName="Recording & Transcript"
            showModal={showModal}
            handleShowModal={() => {
              setShowModal(!showModal)
            }}
            handleUpgrade={() => console.log('upgrade')}
          />
        )}
        <ModalMeetingComponent />
      </div>

      {meetingItem &&
        meetingItem.report.map((item, index) => {
          return (
            item.component.component_type === 'GENERATION' && (
              <Generation
                key={index}
                generationItem={item}
                getMeetingDetail={getMeetingDetail}
                status={item.status}
              />
            )
          )
        })}

      {meetingItem &&
        meetingItem.report.map((item, index) => {
          return (
            item.component.component_type === 'FORM' && (
              <Forms
                formsTemplateItem={item?.result?.result || []}
                name={item.component.name}
                key={index}
                id={item.id}
                url={meetingItem ? meetingItem?.meeting_file : ''}
                status={item?.status}
                getMeetingDetail={getMeetingDetail}
              />
            )
          )
        })}

      <div
        style={{ display: 'flex', margin: '15px 0', flexDirection: 'column' }}
      >
        <TopicsContainer
          topics={meetingItem ? meetingItem.topics : []}
          getMeetingDetail={getMeetingDetail}
          url={meetingItem ? meetingItem?.meeting_file : ''}
        />
        <br />
        <TasksContainer />
      </div>
      <UnlockFeature
        namePlan="Premium"
        featureName="Chat"
        showModal={showModalChat}
        handleShowModal={() => {
          setShowModalChat(!showModalChat)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
      <UnlockFeature
        namePlan="Pro"
        featureName="Custom Words"
        showModal={showModalCW}
        handleShowModal={() => {
          console.log('words')
          setShowModalCW(!showModalCW)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </div>
  )
}

export default MeetingDetialsContainer
