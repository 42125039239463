import { Button, Checkbox, Form, FormInstance, Input } from 'antd'
import React, { useEffect } from 'react'
import RecipientsComponent from '../recipients'
import MeetingsDataService from '../../../../services/meetings'
import { toast } from 'react-toastify'
import { useMeeting } from '../../../../context/meetingContext'
import { useMeetingActions } from '../../../../hooks/useMeetingActions'
import { LinkOutlined } from '@ant-design/icons'

interface PropsSendNotes {
  setClosePopOver: (value: boolean) => void
  closePopOver: boolean
  form: FormInstance
}

const SendNotes: React.FC<PropsSendNotes> = ({ 
  setClosePopOver, 
  closePopOver, 
  form,
}) => {
  const { meetingItem, setMeetingItem, setIsLoadingUpdateMeeting } = useMeeting()
  const { getMeetingDetail } = useMeetingActions()
  
  useEffect(() => {
    form.setFieldsValue({
      recipients: meetingItem?.recipients
    })
  }, [meetingItem, form])

  const handleSendNotes = (formData: any) => {
    let recipientsToSend: Meetings.Recipients[]
    let recipientsToAdd: Meetings.Recipients[]
    
    // Combine existing and new recipients, removing duplicates
    recipientsToAdd = [...meetingItem?.recipients || [], ...formData.temporalRecipients || []].filter(
      (r: Meetings.Recipients, index: number, self: Meetings.Recipients[]) => 
        index === self.findIndex((t: Meetings.Recipients) => t.email === r.email)
    )

    // Remove any recipients that were marked for deletion
    recipientsToAdd = recipientsToAdd.filter(
      (r: Meetings.Recipients) => 
        !formData.temporalDeleteRecipients?.some((tr: Meetings.Recipients) => tr.email === r.email)
    )
    if (meetingItem?.status === 'sent') {
      recipientsToSend = formData.temporalRecipients || []      
    } else {
      recipientsToSend = [...meetingItem?.recipients || [], ...formData.temporalRecipients]
    }
    setIsLoadingUpdateMeeting(true)
    addRecipients(recipientsToAdd)
    const meetingService = new MeetingsDataService()
    meetingService
      .sendNotes(
        meetingItem ? meetingItem.id : '', 
        recipientsToSend,
        formData.selectSlack,
        formData.selectEmail,
      )
      .then(() => {
        toast.success('Notes have been sent', { theme: 'colored', toastId: 'send-notes-success' })
        setClosePopOver(!closePopOver)
        form.resetFields(['selectEmail', 'selectSlack','recipients','temporalRecipients', 'temporalDeleteRecipients'])
      })
      .catch(() => {
        toast.error('The notes have not been sent, try again', { theme: 'colored', toastId: 'send-notes-error' })
      })
      .finally(() => {
        setIsLoadingUpdateMeeting(false)
      })
  }

  const onClickDeleteRecipients = (value: string, isTemporal: boolean = false) => {
    const temporalRecipients = form.getFieldValue('temporalRecipients').filter((x: Meetings.Recipients) => x.email !== value)
    const meetingRecipients = meetingItem?.recipients.filter((x) => x.email !== value) || []
    const allRecipients = [...temporalRecipients, ...meetingRecipients]
    
    if (meetingItem) {
      const data = {
        ...meetingItem,
        recipients: allRecipients
      } 
      setMeetingItem(data)
    }
  }
  
  const addRecipients = (data: {
    email: string
    last_name: string
    first_name: string
  }[]) => {
    const meetingService = new MeetingsDataService()
    setIsLoadingUpdateMeeting(true)
    if (meetingItem) {
      meetingService
        .updateMeeting(
          {
            recipients: [...data]
          },
          meetingItem.id
        )
        .then(() => {
          getMeetingDetail(meetingItem.id)
          toast.success(`Recipients have been updated`, { theme: 'colored', toastId: 'update-success' })
          setIsLoadingUpdateMeeting(false)
        })
        .catch((error) => {
          toast.error(`Recipients haven't been updated correctly`, {
            theme: 'colored',
            toastId: 'update-error'
          })
        })
    }
  }
  
  const handleCreateSharingLink = async () => {
    try {
      if (meetingItem?.id) {
        const meetingService = new MeetingsDataService()
        const response = await meetingService.sendSharingLink(meetingItem?.id)
        const url = `https://app.jamy.ai/meetings/details/${meetingItem?.id}/?token=${response.data.key}`
        navigator.clipboard.writeText(url)
        toast.info('Link copied to clipboard', { theme: 'colored', toastId: 'link-copied-to-clipboard' })
      } else {
        toast.error('Error to create sharing link', { theme: 'colored', toastId: 'create-sharing-link-error' })
      }
    } catch (error) {
      toast.error('Error in the service to create sharing link', { theme: 'colored', toastId: 'service-error-create-sharing-link' })
    }
  }

  return (
    <div style={{ padding: '20px', minWidth: '480px' }}>
      <Form 
        form={form} 
        onFinish={handleSendNotes}
        initialValues={{
          selectEmail: true,
          selectSlack: true,
          recipients: meetingItem?.recipients || [],
          temporalRecipients: [],
          inputEmails: [],
          temporalDeleteRecipients: []
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
      >
        <Form.Item name="temporalDeleteRecipients" hidden>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="temporalRecipients" hidden>
          <Input type="hidden" />
        </Form.Item>
        
        <RecipientsComponent
          onClickDelete={onClickDeleteRecipients}
          form={form}
          
        />
        <div style={{ display: 'flex' }}>
          <Form.Item name={'selectEmail'} valuePropName="checked">
            <Checkbox>Email</Checkbox>
          </Form.Item>
          <Form.Item name={'selectSlack'} valuePropName="checked">
            <Checkbox>Slack</Checkbox>
          </Form.Item>
        </div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button 
              type="link" 
              size="small"
              onClick={handleCreateSharingLink}
              icon={<LinkOutlined />}
            >
              Copy link
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button htmlType="submit" type="primary" size="small">
              Done
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SendNotes
