import React, { useEffect, useRef, useState } from 'react'
import {
  StyledItemFormsAnswer,
  StyledItemFormsContainer,
  StyledItemFormsContent,
  StyledItemFormsNumber,
  StyledItemFormsOptions,
  StyledItemFormsQuestion,
  StyledItemFormsContentV2,
  StyledItemFormsAnswerV2
} from './styledComponents'
import { Drawer, Form, FormInstance, Input } from 'antd'
import { StyledH1V2 } from '../../styles/styledComponents'
import moment from 'moment'
import ReactPlayer from 'react-player'

const { TextArea } = Input

export type PropsItemForms = {
  index?: number
  result: {
    name: string
    value: string
    quotes?: any[]
  }
  isEditing: boolean
  form: FormInstance<any>
  url: string
}

const ItemForms: React.FC<PropsItemForms> = ({
  result,
  index,
  isEditing,
  form,
  url
}) => {
  const [open, setOpen] = useState(false)
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef<any>()

  useEffect(() => {
    form.setFieldValue(`form-report-${index}`, result.value)
  }, [])

  return (
    <React.Fragment>
      <StyledItemFormsContainer
        hasQuotes={true}
        onClick={() => {
          !isEditing && setOpen(true)
        }}
      >
        <StyledItemFormsContent>
          <StyledItemFormsNumber>{index}</StyledItemFormsNumber>
          <StyledItemFormsQuestion>{result.name}</StyledItemFormsQuestion>
        </StyledItemFormsContent>
        {!isEditing ? (
          <StyledItemFormsAnswer>{result.value}</StyledItemFormsAnswer>
        ) : (
          <Form.Item
            name={`form-report-${index}`}
            rules={[{ required: true, message: 'Please input your value!' }]}
          >
            <TextArea
              autoSize
              value={result.value}
              style={{ margin: '0.75rem 0' }}
            />
          </Form.Item>
        )}
        <StyledItemFormsOptions></StyledItemFormsOptions>
      </StyledItemFormsContainer>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false)
          setPlaying(false)
        }}
        size="large"
        width={1000}
        title={''}
      >
        <StyledH1V2>FIELD</StyledH1V2>
        <StyledItemFormsContainer hasQuotes={false}>
          <StyledItemFormsContentV2>
            <StyledItemFormsNumber>{index}</StyledItemFormsNumber>
            <StyledItemFormsAnswerV2>{result.name}</StyledItemFormsAnswerV2>
          </StyledItemFormsContentV2>
        </StyledItemFormsContainer>
        <div>
          <StyledH1V2>Value</StyledH1V2>
          <div style={{ lineHeight: '20px', fontSize: '15px' }}>
            {result.value}
          </div>
        </div>
        {result.quotes && result.quotes.length && (
          <StyledH1V2>Quotes</StyledH1V2>
        )}
        {result.quotes &&
          result.quotes.length &&
          result.quotes.map((quote: any, index: number) => {
            return (
              <div key={index} style={{ display: 'flex' }}>
                <div>
                  <p
                    style={{
                      marginRight: '10px',
                      color: '#975DB8',
                      cursor: 'pointer',
                      fontWeight: 'bold'
                    }}
                    onClick={() => {
                      playerRef.current.seekTo(quote.timestamp, 'seconds')
                    }}
                  >
                    {moment.utc(quote.timestamp * 1000).format('HH:mm:ss')}
                  </p>
                </div>
                <div>
                  <p style={{ color: '#5E6DB8', fontWeight: 'bold' }}>
                    {quote.speaker}
                  </p>
                  <p style={{ fontStyle: 'italic' }}>"{quote.quote}..."</p>
                </div>
              </div>
            )
          })}
        <div style={{ marginTop: '20px' }}>
          <ReactPlayer
            ref={playerRef}
            url={url}
            playing={playing}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default ItemForms
