import React, { useContext, useEffect, useState } from 'react'
import Component from '../../../components/component'
import { useForm } from 'antd/es/form/Form'
import JamyContext from '../../../context/jamyContext'
import ComponentsDataService from '../../../services/components'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import Loading from '../../../components/loading'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { steps } from '../../../components/walkthroughs/components/create'
import { useNavigate } from 'react-router-dom'
import { validateWalks } from '../../../utils/validateWalks'
import UserDataService from '../../../services/users'

export interface FormDataF {
  key: string
  name: string
  description: string | string[]
  type: string
}

const CreateComponentContainer: React.FC = () => {
  const { org, user, getUser } = useContext(JamyContext)
  const [formValues, setFormValues] = useState<FormDataF>({
    key: '',
    name: '',
    description: '',
    type: ''
  })
  const [options, setOptions] = useState<{ name: string; value: string }[]>([
    { name: 'option_1', value: '' }
  ])
  const [loading, setLoading] = useState(false)
  const [isFormValuesValid, setIsFormValuesValid] = useState(false)
  const [form] = useForm()
  const [AIModalForm] = useForm()
  const [instruction, setInstruction] = useState<string>('')
  const componentsDataService = new ComponentsDataService()
  const [runWalk, setRunWalk] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const navigate = useNavigate()
  const userDataService = new UserDataService()
  const [AIFormIsLoading, setAIFormIsLoading] = useState(false)
  const [isModalAIOpen, setIsModalAIOpen] = useState(false)

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])
  const usersService = new UserDataService()
  const [editors, setEditors] = useState<any[]>([])

  const onFinish = (data: any) => {
    const { instructions: _, formComponentRecords, ...restOfData } = data

    setLoading(true)
    const dataRequest = {
      ...restOfData,
      organization: org?.id,
      owner_id: user?.id,
      editors_id: [user ? user.id : '', ...(data.editors || [])],
      public: data.public ? data.public : false,
      prompt:
        data.component_type === 'GENERATION'
          ? handlePromptGeneration()
          : handlePromptForm()
    }

    if (data.edit === false || data.edit === undefined) {
      componentsDataService
        .createComponent(dataRequest)
        .then((response: any) => {
          toast.success('Component created succesfully', { theme: 'colored' })
          setLoading(false)
          navigate(`/components/create/${response.data.id}/`)
        })
        .catch((error: AxiosError) => {
          console.error(error)
          setLoading(false)
          toast.error(`${error.message}`, { theme: 'colored' })
        })
    } else {
      componentsDataService
        .updateComponentById(data.id, dataRequest)
        .then((response) => {
          toast.success('Component updated succesfully', { theme: 'colored' })
          setLoading(false)
          navigate('/components')
        })
        .catch((error: AxiosError) => {
          console.error(error)
          setLoading(false)
          toast.error(`${error.message}`, { theme: 'colored' })
        })
    }
  }

  const handlePromptForm = () => {
    const currentFormComponentRecords =
      form.getFieldValue('formComponentRecords') || []
    const prompt = currentFormComponentRecords.map((el: any) => {
      if (el.type === 'select' || el.type === 'multi_select') {
        return {
          description: el.description.join(', '),
          type: el.type,
          name: el.name
        }
      } else {
        return {
          description: el.description,
          type: el.type,
          name: el.name
        }
      }
    })

    return { prompt }
  }

  const handlePromptGeneration = () => {
    return {
      prompt: [
        {
          name: form
            .getFieldValue('instructions')
            .map(
              (elemento: { value: string }, index: number) =>
                `${index + 1}. ${elemento.value}`
            )
            .join('\n '),
          description: '',
          type: ''
        }
      ]
    }
  }

  const handleChangeForm = (value: string, input: string) => {
    setFormValues({
      ...formValues,
      [input]: value
    })
  }

  useEffect(() => {
    if (formValues.name.trim() !== '' && formValues.type.trim() !== '') {
      if (formValues.type !== 'select' && formValues.type !== 'multi_select') {
        if (
          typeof formValues.description === 'string' &&
          formValues.description.trim() !== ''
        ) {
          setIsFormValuesValid(true)
        }
      } else {
        setIsFormValuesValid(options[0].value !== '' ? true : false)
      }
    }
  }, [formValues, options])

  const handleChangeFormSubmit = () => {
    if (isFormValuesValid) {
      const currentFormComponentRecords =
        form.getFieldValue('formComponentRecords') || []
      const newValue =
        formValues.type === 'select' || formValues.type === 'multi_select'
          ? {
              ...formValues,
              key: currentFormComponentRecords.length,
              description: options.map((el) => el.value),
              isEditing: false
            }
          : {
              ...formValues,
              key: currentFormComponentRecords.length,
              isEditing: false
            }

      form.setFieldValue(
        'formComponentRecords',
        currentFormComponentRecords.concat(newValue)
      )

      setFormValues({
        key: '',
        name: '',
        description: '',
        type: ''
      })
      setOptions([{ name: 'option_1', value: '' }])
    }
  }

  const handleOptionChange = (value: string, name: string) => {
    setOptions((prev) =>
      prev.map((option) =>
        option.name === name
          ? { ...option, value: value.replace(/,/g, '') }
          : option
      )
    )
  }

  const addOptionToArray = () => {
    const newOptionKey = `option_${options.length + 1}`
    setOptions((prev) => [
      ...prev,
      { name: newOptionKey, value: '' } // Agrega un nuevo objeto con clave dinámica
    ])
  }

  const updateUserWalkValue = () => {
    userDataService
      .updateUserWalkthroughs(
        {
          templates_walkthrough: true
        },
        user ? user.id : ''
      )
      .then((data) => {
        console.log('User updated', data)
        getUser()
      })
      .catch((e) => {
        console.error('Error updating user templates_walkthrough value', e)
      })
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if ([STATUS.FINISHED].includes(status as any)) {
      localStorage.setItem('third-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        updateUserWalkValue()
      }
      setRunWalk(false)
      setIsModalAIOpen(true)
    } else if ([STATUS.SKIPPED].includes(status as any)) {
      updateUserWalkValue()
      setRunWalk(false)
    }
  }

  const handleAISubmitForm = () => {
    setAIFormIsLoading(true)
    AIModalForm.validateFields()
      .then((values) => {
        componentsDataService
          .generateAIComponent(values.AIDescription)
          .then((response: any) => {
            toast.success('Component generated succesfully', {
              theme: 'colored'
            })
            form.setFieldValue('component_type', response.data.component_type)
            form.setFieldValue('description', response.data.description)
            form.setFieldValue('name', response.data.name)

            if (response.data.component_type === 'GENERATION') {
              const result = response.data.prompt.prompt[0].name
                .split('\n')
                .map((line: string) => line.substring(3, 1000))

              form.setFields(
                result.map((val: string, index: number) => ({
                  name: ['instructions', index, 'value'],
                  value: val.trimStart()
                }))
              )
              form.setFields(
                result.map((_: any, index: number) => ({
                  name: ['instructions', index, 'isEditing'],
                  value: false
                }))
              )
            } else if (response.data.component_type === 'FORM') {
              response.data.prompt.prompt.map((el: any, index: number) => {
                form.setFields([
                  {
                    name: ['formComponentRecords', index, 'key'],
                    value: index
                  },
                  {
                    name: ['formComponentRecords', index, 'isEditing'],
                    value: false
                  },
                  {
                    name: ['formComponentRecords', index, 'type'],
                    value: el.type
                  },
                  {
                    name: ['formComponentRecords', index, 'name'],
                    value: el.name
                  },
                  {
                    name: ['formComponentRecords', index, 'description'],
                    value:
                      el.type === 'select' || el.type === 'multi_select'
                        ? el.description
                            .split(', ')
                            .map((item: string) => item.trim())
                        : el.description
                  }
                ])
              })
            }

            AIModalForm.resetFields()
            setAIFormIsLoading(false)
            setIsModalAIOpen(false)
          })
          .catch((error: AxiosError) => {
            console.error(error)
            toast.error(`${error.message}`, { theme: 'colored' })
            setAIFormIsLoading(false)
          })
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
        setAIFormIsLoading(false)
      })
  }

  useEffect(() => {
    usersService
      .getAllUsers()
      .then((response) => {
        setEditors(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Joyride
            run={runWalk}
            steps={steps}
            showSkipButton={true}
            hideBackButton={false}
            continuous={true}
            hideCloseButton
            styles={{ options: { primaryColor: '#E0AA25' } }}
            disableCloseOnEsc
            disableOverlayClose
            stepIndex={stepIndex}
            callback={handleJoyrideCallback}
          />
          <Component
            form={form}
            instruction={instruction}
            setInstruction={setInstruction}
            onFinish={onFinish}
            handleChangeForm={handleChangeForm}
            formValues={formValues}
            isFormValuesValid={isFormValuesValid}
            handleChangeFormSubmit={handleChangeFormSubmit}
            handleOptionChange={handleOptionChange}
            addOptionToArray={addOptionToArray}
            options={options}
            editors={editors}
            AIModalForm={AIModalForm}
            handleAISubmitForm={handleAISubmitForm}
            AIFormIsLoading={AIFormIsLoading}
            isModalAIOpen={isModalAIOpen}
            closeModalAI={() => setIsModalAIOpen(false)}
            openModalAI={() => setIsModalAIOpen(true)}
          />
        </div>
      )}
    </div>
  )
}

export default CreateComponentContainer
