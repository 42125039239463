import { Popover, Button, Input } from "antd"
import { useState, useContext, useEffect } from "react"
import Loading from '../loading';
import JamyContext from '../../context/jamyContext';
import MeetingsDataService from '../../services/meetings';
import ComponentsDataService from "../../services/components";
import UnlockFeature from "../unlockFeature";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useMeetingActions } from "../../hooks/useMeetingActions";
import { ContainerModalMeetingComponent, StyledButtonAI } from "./styledComponents";

const ModalMeetingComponent = () => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingAddComponent, setLoadingAddComponent] = useState(false)
  const { isAuthenticated, permissions, user } = useContext(JamyContext)
  const componentDataService = new ComponentsDataService()
  const [meetingComponents, setMeetingComponents] = useState<Components.ResultComponent[]>([])
  const [showUnlockFeature, setShowUnlockFeature] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const { meeting_id } = useParams()
  const { getMeetingDetail } = useMeetingActions()

    useEffect(() => {
      if (showModal) {
        searchMeetingComponents(searchQuery)
      }
    }, [showModal])

    const renderComponentItem = (component: Components.ResultComponent) => (
      <div key={component.id} style={{ 
        padding: 8, 
        borderBottom: '1px solid #f0f0f0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div>
          <div style={{ fontWeight: 500 }}>{component.name}</div>
          <div style={{ color: '#666', fontSize: 12 }}>{component.description}</div>
        </div>
        <Button 
          size="small" 
          onClick={() => handleAddComponent(component.id)}
          disabled={!permissions?.includes('meeting_components')}
          type="primary"
          loading={loadingAddComponent}
        >
          Add
        </Button>
      </div>
    );

    const handleAddComponent = async (componentId: string) => {
      setLoadingAddComponent(true)
      try {
        await componentDataService.createMeetingComponent({
          component_id: componentId,
          meeting_id: meeting_id || ""
        })
        toast.success('Component added to the queue. It will be done in <1 min.', {theme: 'colored', toastId: 'add-component-success'});
        getMeetingDetail(meeting_id || "")
        setShowModal(false)
      } catch (error) {
        toast.error('Error adding component', {theme: 'colored', toastId: 'add-component-error'});
      } finally {
        setLoadingAddComponent(false)
      }
    };
    
    const searchMeetingComponents = async (value: string) => {
      setSearchQuery(value)
      setLoading(true)
      
      const controller = new AbortController()
      const { signal } = controller
      
      try {
        const searchValue = value.length > 3 ? value : ""
        const response = await componentDataService.searchMeetingComponents(searchValue, { signal })
        setMeetingComponents(response.data)
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          toast.error('Error searching components', {theme: 'colored', toastId: 'search-components-error'})
        }
      } finally {
        setLoading(false)
      }
      
      return () => controller.abort()
    }

    return (
      <ContainerModalMeetingComponent>
        <Popover
          content={
            <div style={{ minWidth: 400, maxWidth: 400, minHeight: 250, maxHeight: 500, overflow: 'auto' }}>
              <Input.Search
                placeholder="Search my components"
                allowClear
                onChange={(e) => searchMeetingComponents(e.target.value)}
                style={{ marginBottom: 12 }}
              />
              { 
                loading ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                    <Loading size='small' />
                  </div>
                  :
                  meetingComponents.length > 0 ?
                    meetingComponents.map(component => renderComponentItem(component))
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                      <p>No components found</p>
                    </div>
              }
            </div>
          }
          trigger="click"
          placement="bottom"
          open={showModal}
          onOpenChange={() => {
            if (!isAuthenticated) return null
            if (permissions && !permissions.includes('meeting_components')) return setShowUnlockFeature(!showUnlockFeature)
            setShowModal(!showModal)
          }}
        >
          <StyledButtonAI>
            Add New Component{' '}
            <span role="img" aria-label="wand">
              🪄
            </span>
          </StyledButtonAI>
        </Popover>
        <UnlockFeature
            namePlan="Premium"
            featureName="Meeting Components"
            showModal={showUnlockFeature}
            handleShowModal={() => setShowUnlockFeature(!showUnlockFeature)}
            handleUpgrade={() => {}}
        />
      </ContainerModalMeetingComponent>
    )
}

export default ModalMeetingComponent;

