import React from 'react'
import { Skeleton } from 'antd'

const SkeletonGeneration: React.FC = () => {
  return (
    <Skeleton
      active
      paragraph={{
          rows: 4,
        width: ['100%', '100%', '100%', '60%']
      }}
    />
  )
}

export default SkeletonGeneration
